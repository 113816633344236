import React, { useEffect, useState } from "react";
import { Table, Row, Col ,Button , Modal} from "antd";
import endpoint from "../../helpers/Endpoint";

const Index = (props) => {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0); // For total records from API
  const [sorter, setSorter] = useState({}); // To track sorting state
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEmails, setModalEmails] = useState([]); // Emails for modal display
  const [locAddress, setLocAddress] = useState(""); // State for locAddress
const [locAddressModalVisible, setLocAddressModalVisible] = useState(false); // State for modal visibility

const handleNameClick = (address) => {
  setLocAddress(address || "No Address Available"); // Handle null or undefined values
  setLocAddressModalVisible(true);
};

const handleLocAddressModalClose = () => {
  setLocAddressModalVisible(false);
};


  useEffect(() => {
    getInvitationsList();
  }, [page, pageSize, sorter]); // Include dependencies for pagination and sorting

  const getInvitationsList = async () => {
    try {
      setLoading(true);
      const sortField = sorter?.field || ""; // Sort field
      const sortOrder = sorter?.order === "ascend" ? "asc" : "desc"; // Sort order

      const result = await fetch(
        `${endpoint}/api/v1/admin/branch/listInvitations?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`,
        {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const data = await result.json();
      if (data) {
        const processedData = data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1 + (page - 1) * pageSize, // Add sequential numbering
        }));

        setInvitations(processedData);
        setTotalRecords(data.totalCount); // Update total records from API
      }
      setLoading(false);
    } catch (error) {
      console.error("Something went wrong:", error);
      setLoading(false);
    }
  };

  const backbtn = () => {
    props.history.goBack();
  };

  
  // Show modal with full list of emails
  const showEmailsModal = (emails) => {
    setModalEmails(emails);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalEmails([]);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filters: [
      ...new Set(invitations.map((item) => {
        const keys = dataIndex.split('.'); // Split the dataIndex string for nested properties
        let value = item;
        keys.forEach((key) => {
          value = value[key]; // Access the nested property
        });
        return value;
      })).values(),
    ].map((value) => ({
      text: value || "No Data", // Handle undefined or null values
      value: value || "",
    })),
    onFilter: (value, record) => {
      const keys = dataIndex.split('.'); // Split the dataIndex string for nested properties
      let fieldValue = record;
      keys.forEach((key) => {
        fieldValue = fieldValue[key]; // Access the nested property
      });
      return fieldValue?.toString().toLowerCase().includes(value.toLowerCase());
    },
  });
  
  
  const tableColumns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "serialNo",
    //   key: "serialNo",
    //   width: "5%",
    //   render: (serialNo) => <p>{serialNo ? serialNo : ""}</p>,
    // },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "15%",
    //   ...getColumnSearchProps("name"), // Ant Design filter
    //   render: (text) => <strong>{text}</strong>,
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...getColumnSearchProps("name"), // Ant Design filter
      render: (text, record) => (
        <strong
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handleNameClick(record.address?.locAddress)}
        >
          {text}
        </strong>
      ),
    },
    
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      ...getColumnSearchProps("type"), // Ant Design filter
      render: (text) => <p>{text}</p>,
    },
    {
      title: "No of Invitations",
      key: "invitations",
      width: "10%",
      sorter: (a, b) => a.userEmails.length - b.userEmails.length,
      render: (text, record) => (
        <center>
          <p>{record.userEmails.length}</p>
        </center>
      ),
    },
    {
      title: "Invited By",
      dataIndex: "userEmails",
      key: "userEmails",
      width: "20%",
      render: (emails) => (
        <>
          {emails.slice(0, 2).map((email, index) => (
            <div key={index}>
              {email}
              {index === 1 && emails.length > 2 && (
                <Button
                  type="link"
                  style={{ marginLeft: "8px" }}
                  onClick={() => showEmailsModal(emails)}
                >
                  See More...
                </Button>
              )}
            </div>
          ))}
        </>
      ),
    },
    {
      title: "City Name",
      key: "address.cityName",
      width: "10%",
      ...getColumnSearchProps("address.cityName"), // Ant Design filter for city name
      render: (text, record) => <p>{record.address?.cityName || "Zirakpur"}</p>,
    },
    {
      title: "Date",
      key: "createdAt",
      width: "15%",
      //sorter: true,
      sorter: (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <p>{new Date(record.createdAt).toLocaleString()}</p>
      ),
    },
  ];
  

  return (
    <div
      style={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: 10,
        height: "70vh",
      }}
    >
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <Row gutter={8}>
                <Col span={14}>
                  <h3 className="card-title">
                    <span className="icon-text">
                      <span className="icon" onClick={() => backbtn()}>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                    </span>
                    User Area Invitations
                  </h3>
                </Col>
              </Row>
              <div className="row">
                <div className="column">
                  <Table
                    bordered
                    loading={loading}
                    dataSource={invitations}
                    columns={tableColumns}
                    pagination={{
                      total: totalRecords,
                      current: page,
                      pageSize: pageSize,
                    //   showSizeChanger: true,
                    //   pageSizeOptions: ["5", "10", "20"],
                      onChange: (current, size) => {
                        setPage(current);
                        setPageSize(size);
                      },
                    }}
                    onChange={(pagination, filters, sorter) => {
                      const { current, pageSize } = pagination;
                      setPage(current);
                      setPageSize(pageSize || 10);
                      setSorter(sorter); // Update sorting state
                    }}
                  ></Table>
                </div>
              </div>
            </div>
          </div>
        </div>

         {/* Modal for displaying full email list */}
         <Modal
          title="Full Email List"
          visible={modalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
        >
          <ul>
            {modalEmails.map((email, index) => (
              <li key={index}>{email}</li>
            ))}
          </ul>
        </Modal>
        <Modal
  title="Location Address"
  visible={locAddressModalVisible}
  onCancel={handleLocAddressModalClose}
  footer={[
    <Button key="close" onClick={handleLocAddressModalClose}>
      Close
    </Button>,
  ]}
>
  <p>{locAddress}</p>
</Modal>

      </>
    </div>
  );
};

export default Index;
