import React, { Component, useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import Notification from "react-bulma-notification";
import { Row, Col } from "antd";
import "./restaurant-add.css";
import {
  Tag,
  Input,
  Tooltip,
  Icon,
  Button,
  Switch,
  Dropdown,
  Item,
  Menu,
  Radio,
  Checkbox,
  Select,
  Popconfirm,
  Divider
} from "antd";
import { Country, State, City}  from 'country-state-city';
const CheckboxGroup = Checkbox.Group;
// import { Tag } from "antd";
const { Option } = Select;
const removeAccents = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
let cities=City.getCitiesOfCountry("NO")

class RestaurantForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: [],
      superCats: [],
      driveoutCharge: "",
      sCategory: "",
      name: "",
      email: "",
      phoneNo: "",
      taxNo: "",
      line1: "",
      line2: "",
      city: "",
      pin: "",
      state: "",
      country: "",
      tflamount: 0,
      description: "",
      style: "",
      id: "",
      accountNumber: "",
      tdEnhPercentage: "",
      website: "",
      taccountantEmail: "",
      isRestaurantAvailable : "",
      restaurantEmail: "",
      inputVisible: false,
      sCatType: "",
      kitchenpermission: false,
      barpermission: false,
      tippermission: false,
      deliverypermission: false,
      pickuppermission: false,
      qrcodeTablepermission: false,
      menu: {},
      deviceId: [],
      selectedDevices: [],
      updateselectedDevices: [],
      updatedeviceId: [],
      longitude: "0",
      latitude: "0",
      couponExpiresIn: "",
      couponPermissionChange: false,
      vippsPermissionChange: false,
      merchantSerialNumber: "",
      merchantSerialNumberPos: "",
      isTreeDriveAllowed: false,
      tdDiscountPermission: false,
      isNewReports: false,
      isWebOpperKonto:false,
      isDriveMyself: false,
      isTdvipps: false,
      isFindDriver: false,
      isTreeDriveDriveOutAllowed: false,
      isTreeDrivePickUpAllowed: false,
      tdDistance: "",
      tdServiceCharge: "",
      tdExtraPriceIncrease: "",
      tdFlatRateDriveOut: "",
      tdExtraRateDriveOut: "",
      tdStaticDriveOut: "",
      tdFlatDriveOutDistance: "",
      isWeekly: false,
      isVendorAllowed:false,
      isIngredientAllowed:true,
      tdDiscount: "",
      tdDiscountPickUp: "",
      reserveTablePermission: false,
      minimumOrder: 0,
      machAccess: "",
      branchAddress:{},
      searchCity:"",
      filteredCityData:cities,
      branchType:"",
      range1Max: 100000,
      range1Min: 0,
      discount1: 0,
      range2Max: 100000,
      range2Min: 0,
      discount2: 0,
      range3Max: 100000,
      range3Min: 0,
      discount3: 0,
      range1MaxTA: 100000,
      range1MinTA: 0,
      discount1TA: 0,
      range2MaxTA: 100000,
      range2MinTA: 0,
      discount2TA: 0,
      range3MaxTA: 100000,
      range3MinTA: 0,
      discount3TA: 0,
      range4MaxTA: 100000,
      range4MinTA: 0,
      discount4TA: 0,
      range1MaxIDriveMyself: 100000,
      range1MinIDriveMyself: 0,
      perKmFee1IDriveMyself: 0,
      range2MaxIDriveMyself: 100000,
      range2MinIDriveMyself: 0,
      perKmFee2IDriveMyself: 0,
      range3MaxIDriveMyself: 100000,
      range3MinIDriveMyself: 0,
      perKmFee3IDriveMyself: 0,
      perDelFeeIDriveMyself: 0,
      accountId: "",
      tidypayusername: "",
      tidypaypassword: "",
      tidypaypasswordweb: "",
      tidypaypassword6inch: "",
      // tidypaypermission: false,
      // dinteropermission: false,
      // verifonepermission: false,
      minDepositAllowed: 0,
      advanceDeposit: 0,
      minDiffAllowed: 0,
      webpayment: "",
      machinepayment: "",
      urlVippsDynamic:"",
      vippsClientId:"",
      vippsClientSecret:"",
      ocp_Apim_Subscription_key:""
    };
  }

  componentDidMount = () => {
    const { restaurant, editing, superCats } = this.props;
    if (editing) {
      const qrcodeTablepermission = restaurant.isQrAllow
        ? restaurant.isQrAllow
        : false;
      this.setState({
        name: restaurant.name,
        email: restaurant.email,
        phoneNo: restaurant.phoneNo,
        taxNo: restaurant.taxNo,
        line1: restaurant.address.line1,
        line2: restaurant.address.line2,
        searchCity: restaurant.address.cityName,
        city: restaurant.address.city,
        pin: restaurant.address.pin,
        tflamount: restaurant.tfl.amount,
        state: restaurant.address.state,
        country: restaurant.address.country,
        description: restaurant.description,
        accountNumber: restaurant.accountNumber,
        tdEnhPercentage: restaurant.tdEnhPercentage,
        id: restaurant._id,
        website: restaurant.website,
        driveoutCharge: restaurant.driveoutCharge,
        taccountantEmail: restaurant.taccountantEmail,
        isRestaurantAvailable:restaurant.isRestaurantAvailable,
        restaurantEmail: restaurant.restaurantEmail,
        superCats: superCats,
        kitchenpermission: restaurant.additional.kitchen,
        barpermission: restaurant.additional.bar,
        tippermission: restaurant.additional.tip,
        qrcodeTablepermission: qrcodeTablepermission,
        deliverypermission: restaurant.additional.delivery,
        pickuppermission: restaurant.additional.pickup,
        devicesData: restaurant.devices,
        //  theming fields
        categoryBackColor: restaurant.colors.categoryBackColor,
        categoryTitleColor: restaurant.colors.categoryTitleColor,
        productColor: restaurant.colors.productColor,
        priceColor: restaurant.colors.priceColor,
        ingredentTextColor: restaurant.colors.ingredentTextColor,
        orderNowBtnBackground: restaurant.colors.orderNowBtnBackground,
        orderNowTextColor: restaurant.colors.orderNowTextColor,
        viewCartTextColor: restaurant.colors.viewCartTextColor,
        viewProductColor: restaurant.colors.viewProductColor,
        longitude: restaurant.address.location.coordinates[0],
        latitude: restaurant.address.location.coordinates[1],
        couponExpiresIn: restaurant.couponExpiresTime,
        couponPermissionChange: restaurant.additional.couponPermission,
        vippsPermissionChange: restaurant.additional.vippsPermission,
        merchantSerialNumber: restaurant.merchantSerialNumber,
        merchantSerialNumberPos: restaurant.merchantSerialNumberPos,
        tdDiscountPermission: restaurant.additional.tdDiscountPermission,
        isNewReports: restaurant.isNewReports,
        isWebOpperKonto: restaurant.isWebOpperKonto,
        isTreeDriveAllowed: restaurant.isTreeDriveAllowed,
        isDriveMyself: restaurant.DriveMyselfPermission,
        isTdvipps: restaurant.isTdvipps,
        isFindDriver: restaurant.isFindDriver,
        isTreeDriveDriveOutAllowed: restaurant.isTreeDriveDriveOutAllowed,
        isTreeDrivePickUpAllowed: restaurant.isTreeDrivePickUpAllowed,
        tdDistance: restaurant.tdDistance,
        tdServiceCharge: restaurant.tdServiceCharge,
        tdExtraPriceIncrease: restaurant.tdExtraPriceIncrease,
        tdFlatRateDriveOut: restaurant.tdFlatRateDriveOut,
        tdExtraRateDriveOut: restaurant.tdExtraRateDriveOut,
        tdStaticDriveOut: restaurant.tdStaticDriveOut,
        tdFlatDriveOutDistance: restaurant.tdFlatDriveOutDistance,
        isWeekly: restaurant.weeklyReport ? restaurant.weeklyReport : false,
        isVendorAllowed: restaurant.isVendorAllowed ? restaurant.isVendorAllowed : false,
        isIngredientAllowed: restaurant.isIngredientAllowed ,
        reserveTablePermission: restaurant.additional.reserveTablePermission
          ? restaurant.additional.reserveTablePermission
          : false,
        tdDiscount: restaurant.tdDiscount,
        tdDiscountPickUp: restaurant.tdDiscountPickUp,
        minimumOrder: restaurant.minimumOrder ? restaurant.minimumOrder : 0,
        machAccess: restaurant.machAccess ? restaurant.machAccess : "",
        branchAddress: restaurant.branchAddress ? restaurant.address : "",
        branchType: restaurant.branchType ? restaurant. branchType : "",
        range1Max: restaurant?.tdDriveOutDiscountRange.range1Max
          ? restaurant?.tdDriveOutDiscountRange.range1Max
          : 100000,
        range1Min: restaurant?.tdDriveOutDiscountRange.range1Min
          ? restaurant?.tdDriveOutDiscountRange.range1Min
          : 0,
        range2Max: restaurant?.tdDriveOutDiscountRange.range2Max
          ? restaurant?.tdDriveOutDiscountRange.range2Max
          : 100000,
        range2Min: restaurant?.tdDriveOutDiscountRange.range2Min
          ? restaurant?.tdDriveOutDiscountRange.range2Min
          : 0,
        range3Max: restaurant?.tdDriveOutDiscountRange.range3Max
          ? restaurant?.tdDriveOutDiscountRange.range3Max
          : 100000,
        range3Min: restaurant?.tdDriveOutDiscountRange.range3Min
          ? restaurant?.tdDriveOutDiscountRange.range3Min
          : 0,
        discount1: restaurant?.tdDriveOutDiscountRange.discount1
          ? restaurant?.tdDriveOutDiscountRange.discount1
          : 0,
        discount2: restaurant?.tdDriveOutDiscountRange.discount2
          ? restaurant?.tdDriveOutDiscountRange.discount2
          : 0,
        discount3: restaurant?.tdDriveOutDiscountRange.discount3
          ? restaurant?.tdDriveOutDiscountRange.discount3
          : 0,
        range1MaxTA: restaurant?.tdTakeAwayDiscountRange.range1Max
          ? restaurant?.tdTakeAwayDiscountRange.range1Max
          : 100000,
        range1MinTA: restaurant?.tdTakeAwayDiscountRange.range1Min
          ? restaurant?.tdTakeAwayDiscountRange.range1Min
          : 0,
        range2MaxTA: restaurant?.tdTakeAwayDiscountRange.range2Max
          ? restaurant?.tdTakeAwayDiscountRange.range2Max
          : 100000,
        range2MinTA: restaurant?.tdTakeAwayDiscountRange.range2Min
          ? restaurant?.tdTakeAwayDiscountRange.range2Min
          : 0,
        range3MaxTA: restaurant?.tdTakeAwayDiscountRange.range3Max
          ? restaurant?.tdTakeAwayDiscountRange.range3Max
          : 100000,
        range3MinTA: restaurant?.tdTakeAwayDiscountRange.range3Min
          ? restaurant?.tdTakeAwayDiscountRange.range3Min
          : 0,
        range4MaxTA: restaurant?.tdTakeAwayDiscountRange.range4Max
          ? restaurant?.tdTakeAwayDiscountRange.range4Max
          : 100000,
        range4MinTA: restaurant?.tdTakeAwayDiscountRange.range4Min
          ? restaurant?.tdTakeAwayDiscountRange.range4Min
          : 0,
        discount1TA: restaurant?.tdTakeAwayDiscountRange.discount1
          ? restaurant?.tdTakeAwayDiscountRange.discount1
          : 0,
        discount2TA: restaurant?.tdTakeAwayDiscountRange.discount2
          ? restaurant?.tdTakeAwayDiscountRange.discount2
          : 0,
        discount3TA: restaurant?.tdTakeAwayDiscountRange.discount3
          ? restaurant?.tdTakeAwayDiscountRange.discount3
          : 0,
        discount4TA: restaurant?.tdTakeAwayDiscountRange.discount4
          ? restaurant?.tdTakeAwayDiscountRange.discount4
          : 0,
        range1MaxIDriveMyself: restaurant?.iDriveMyselfFeeRange.range1Max
          ? restaurant?.iDriveMyselfFeeRange.range1Max
          : 0,
        range1MinIDriveMyself: restaurant?.iDriveMyselfFeeRange.range1Min
          ? restaurant?.iDriveMyselfFeeRange.range1Min
          : 0,
        perKmFee1IDriveMyself: restaurant?.iDriveMyselfFeeRange.perKmFee1
          ? restaurant?.iDriveMyselfFeeRange.perKmFee1
          : 0,
        range2MaxIDriveMyself: restaurant?.iDriveMyselfFeeRange.range2Max
          ? restaurant?.iDriveMyselfFeeRange.range2Max
          : 0,
        range2MinIDriveMyself: restaurant?.iDriveMyselfFeeRange.range2Min
          ? restaurant?.iDriveMyselfFeeRange.range2Min
          : 0,
        perKmFee2IDriveMyself: restaurant?.iDriveMyselfFeeRange.perKmFee2
          ? restaurant?.iDriveMyselfFeeRange.perKmFee2
          : 0,
        range3MaxIDriveMyself: restaurant?.iDriveMyselfFeeRange.range3Max
          ? restaurant?.iDriveMyselfFeeRange.range3Max
          : 0,
        range3MinIDriveMyself: restaurant?.iDriveMyselfFeeRange.range3Min
          ? restaurant?.iDriveMyselfFeeRange.range3Min
          : 0,
        perKmFee3IDriveMyself: restaurant?.iDriveMyselfFeeRange.perKmFee3
          ? restaurant?.iDriveMyselfFeeRange.perKmFee3
          : 0,
        perDelFeeIDriveMyself: restaurant?.iDriveMyselfFeeRange.perDelFee
          ? restaurant?.iDriveMyselfFeeRange.perDelFee
          : 0,
        accountId: restaurant.accountId,
        tidypayusername: restaurant.tidypayusername,
        tidypaypassword: restaurant.tidypaypassword,
        tidypaypasswordweb: restaurant.tidypaypasswordweb,
        tidypaypassword6inch: restaurant.tidypaypassword6inch,
        // tidypaypermission: restaurant.tidypaypermission,
        // dinteropermission: restaurant.dinteropermission,
        // verifonepermission: restaurant.verifonepermission,
        minDepositAllowed: restaurant.minDepositAllowed,
        advanceDeposit: restaurant.advanceDeposit,
        minDiffAllowed: restaurant.minDiffAllowed,
        webpayment: restaurant.webpayment,
        machinepayment: restaurant.machinepayment,
        urlVippsDynamic:restaurant.urlVippsDynamic,
        vippsClientId:restaurant.vippsClientId,
        vippsClientSecret:restaurant.vippsClientSecret,
        ocp_Apim_Subscription_key:restaurant.ocp_Apim_Subscription_key,
      });
    }
  };
  10000;
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleChange = (e) => {
    console.log("inside change--->", e.target.name);
    console.log("inside change value--->", e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
FilterCityData=(e)=>{
  


    // e.preventDefault()
    console.log("-------e",e.target.value)
    this.setState({searchCity:e.target.value})

    let filterData=cities.filter((city)=>{
          let cityName= (city.name).toLowerCase()
          let searchText=(e.target.value).toLowerCase()
          const normalizedString =  removeAccents(cityName)
          let includesText=normalizedString.includes(searchText)
         

        console.log("---",{normalizedString,cityName,searchText}); // Output: oystese
      return includesText
    })
    console.log("-----filterData--------------",filterData)
    this.setState({filteredCityData:filterData})
  }
  handleLogoChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    var hdr = localStorage.token;
    console.log(formData);
    fetch(`${endpoint}/profile/logo/${this.state.id}`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            localStorage.setItem("logo", result.data);
            this.openNotification("success", "Profile Image uploaded");
            // this.getResProfile();
            this.setState({ editing: false });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  removeDevice = (restId, deviceId) => {
    var newDevices = this.props.restaurant.devices.filter(
      (item) => item.deviceId._id !== deviceId
    );
    this.props.restaurant.devices = newDevices;
    this.props.removeDevice(restId, deviceId);
  };

  checkEmailPresence = (e) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    if (e.target.value) {
      fetch(`${endpoint}/admin/checkrestaurantemail/${e.target.value}`, {
        method: "GET",
        // body: postdata,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token
        }
      })
        .then(handleErrors)
        .then((result) => result.json())
        .then((result) => {
          if (result.status === "failure") {
            console.log(result);
          } else {
            if (result.status == 400) {
              this.setState({ email: "" });
              this.openNotification("error", result.message);
            }
          }
        })
        .catch((error) => console.log(error));
    } else {
    }
  };
  getDevices = () => {};
  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };
  saveInputRef = (input) => (this.input = input);
  handleInputChange = (e) => {
    this.setState({ sCategory: e.target.value });
  };
  svcat = (id, category, type) => {
    if (id && category && type) {
      this.props.saveSuperCategory(id, category, type);
      this.setState({ inputVisible: false });
    } else {
      this.openNotification("error", "Enter all Fields");
    }
  };
  handletypeChange = (value) => {
    this.setState({ sCatType: value });
    console.log(`selected ${value}`);
  };

  kitchenpermissionchange = async (checked) => {
    await this.setState({ kitchenpermission: checked });
    console.log(
      "you have checkd the kitchen permission",
      this.state.kitchenpermission
    );
  };
  barpermissionChange = async (checked) => {
    await this.setState({ barpermission: checked });
  };
  tippermissionChange = async (checked) => {
    await this.setState({ tippermission: checked });
  };
  deliverypermissionChange = async (checked) => {
    await this.setState({ deliverypermission: checked });
  };
  pickuppermissionChange = async (checked) => {
    await this.setState({ pickuppermission: checked });
  };

  reserveTablePermissionHandler = async (checked) => {
    await this.setState({ reserveTablePermission: checked });
  };

  couponPermissionChange = async (checked) => {
    console.log("checked --------->", checked);
    await this.setState({ couponPermissionChange: checked });
    console.log(
      "coupon permission change ,",
      this.state.couponPermissionChange
    );
  };
  vippsPermissionChange = async (checked) => {
    console.log("checked vipps --------->", checked);
    await this.setState({ vippsPermissionChange: checked });
    console.log("vipps permission change ,", this.state.vippsPermissionChange);
  };
  isTreeDriveAllowed = async (checked) => {
    await this.setState({ isTreeDriveAllowed: checked });
    console.log("td discount change ,", this.state.isTreeDriveAllowed);
  };
  isNewReports = async (checked) => {
    await this.setState({ isNewReports: checked });
    console.log("isNewReportsAllowed,", this.state.isNewReports);
  };

  isRestaurantAvailable= async (checked) => {
    await this.setState({ isRestaurantAvailable: checked });
    console.log("isRestaurantAvailable,", this.state.isRestaurantAvailable);
  };
  isWebOpperKonto= async (checked) => {
    await this.setState({  isWebOpperKonto: checked });
    console.log("isWebOpperKonto,", this.state.isWebOpperKonto);
  };
  //*--------------td discount permission start-------------*/
  tdDiscountPermission = async (checked) => {
    await this.setState({ tdDiscountPermission: checked });
  };
  //*--------------td discount permission end-------------*/
  isDriveMyself = async (checked) => {
    await this.setState({ isDriveMyself: checked });
    console.log("------isDriveMyself-------- ,", this.state.isDriveMyself);
  };
  isTdvipps = async (checked) => {
    await this.setState({ isTdvipps: checked });
    console.log("------isTdvipps-------- ,", this.state.isTdvipps);
  };
  isFindDriver = async (checked) => {
    await this.setState({ isFindDriver: checked });
    console.log("------isFindDriver-------- ,", this.state.isFindDriver);
  };
  isTreeDriveDriveOutAllowed = async (checked) => {
    console.log("checked vipps --------->", checked);
    await this.setState({ isTreeDriveDriveOutAllowed: checked });
  };
  isTreeDrivePickUpAllowed = async (checked) => {
    await this.setState({ isTreeDrivePickUpAllowed: checked });
  };
  isWeeklyFunction = async (checked) => {
    console.log("checked vipps --------->", checked);
    await this.setState({ isWeekly: checked });
    console.log("is Monthly false ,", this.state.isWeekly);
  };
  isVendorAllowedFunction = async (checked) => {
    console.log("checked vipps --------->", checked);
    await this.setState({ isVendorAllowed: checked });
    console.log("is Monthly false ,", this.state.isVendorAllowed);
  };
  isIngredientAllowedFunction = async (checked) => {
    console.log("isIngredientAllowed --------->", checked);
    await this.setState({ isIngredientAllowed: checked });
    console.log("is Monthly false ,", this.state.isIngredientAllowed);
  };

  // tidypaypermissionFunction = async (checked) => {
  //   await this.setState({ tidypaypermission: checked });
  // };
  // dinteropermissionHandler = async (checked) => {
  //   await this.setState({ dinteropermission: checked });
  // };
  // verifonepermissionHandler = async (checked) => {
  //   await this.setState({ verifonepermission: checked });
  // };
  qrCodePickupPermissionChange = async (checked) => {
    console.log(checked);
    await this.setState({ qrcodeTablepermission: checked });
  };

  changedevicePicker = async (e) => {
    var arr = [];
    var ids = {};
    e.forEach((element) => {
      ids = {
        deviceId: element
      };
    });

    await this.setState({
      selectedDevices: [...this.state.selectedDevices, ids],
      deviceId: e
    });
  };

  updatedevicePicker = async (e) => {
    var ids = {};
    e.forEach((element) => {
      ids = {
        deviceId: element
      };
    });

    await this.setState({
      updateselectedDevices: [...this.state.updateselectedDevices, ids],
      updatedeviceId: e
    });
  };

  submitSaveRestaurant = (
    e,
    editing,
    name,
    email,
    phoneNo,
    taxNo,
    line1,
    line2,
    city,
    pin,
    state,
    country,
    tflamount,
    description,
    accountNumber,
    tdEnhPercentage,
    id,
    website,
    driveoutCharge,
    taccountantEmail,
    isRestaurantAvailable,
    restaurantEmail,
    kitchenpermission,
    barpermission,
    tippermission,
    deliverypermission,
    pickuppermission,
    selectedDevices,
    categoryBackColor,
    categoryTitleColor,
    productColor,
    priceColor,
    ingredentTextColor,
    orderNowBtnBackground,
    orderNowTextColor,
    viewCartTextColor,
    viewProductColor,
    qrcodeTablepermission,
    longitude,
    latitude,
    couponExpiresIn,
    couponPermissionChange,
    vippsPermissionChange,
    merchantSerialNumber,
    merchantSerialNumberPos,
    isTreeDriveAllowed,
    tdDiscountPermission,
    isNewReports,
     isWebOpperKonto,
    isDriveMyself,
    isTdvipps,
    isFindDriver,
    isTreeDriveDriveOutAllowed,
    isTreeDrivePickUpAllowed,
    tdDistance,
    tdServiceCharge,
    tdExtraPriceIncrease,
    tdFlatRateDriveOut,
    tdExtraRateDriveOut,
    tdStaticDriveOut,
    tdFlatDriveOutDistance,
    isWeekly,
    isVendorAllowed,
    isIngredientAllowed,
    tdDiscount,
    tdDiscountPickUp,
    reserveTablePermission,
    minimumOrder,
    machAccess,
    branchAddress,
    searchCity,
    branchType,
    range1Max,
    range1Min,
    discount1,
    range2Max,
    range2Min,
    discount2,
    range3Max,
    range3Min,
    discount3,
    range1MaxTA,
    range1MinTA,
    discount1TA,
    range2MaxTA,
    range2MinTA,
    discount2TA,
    range3MaxTA,
    range3MinTA,
    discount3TA,
    range4MaxTA,
    range4MinTA,
    discount4TA,
    range1MaxIDriveMyself,
    range1MinIDriveMyself,
    perKmFee1IDriveMyself,
    range2MaxIDriveMyself,
    range2MinIDriveMyself,
    perKmFee2IDriveMyself,
    range3MaxIDriveMyself,
    range3MinIDriveMyself,
    perKmFee3IDriveMyself,
    perDelFeeIDriveMyself,

    accountId,
    tidypayusername,
    tidypaypassword,
    tidypaypasswordweb,
    tidypaypassword6inch,
    minDepositAllowed,
    advanceDeposit,
    minDiffAllowed,
    webpayment,
    machinepayment,
    urlVippsDynamic,
    vippsClientId,
    vippsClientSecret,
    ocp_Apim_Subscription_key
  ) => {
    console.log(isWeekly, "isWeekly");
    console.log(isVendorAllowed, "isVendorAllowed");
    console.log(isIngredientAllowed, "isIngredientAllowed");
    console.log(
      this.state.qrcodeTablepermission,
      "this.state.qrcodeTablepermission"
    );

    this.props.saveRestaurant(
      e,
      editing,
      name,
      email,
      phoneNo,
      taxNo,
      line1,
      line2,
      city,
      pin,
      state,
      country,
      tflamount,
      description,
      accountNumber,
      tdEnhPercentage,
      id,
      website,
      driveoutCharge,
      taccountantEmail,
      isRestaurantAvailable,
      restaurantEmail,
      kitchenpermission,
      barpermission,
      tippermission,
      deliverypermission,
      pickuppermission,
      selectedDevices,
      longitude,
      latitude,
      couponExpiresIn,
      couponPermissionChange,
      vippsPermissionChange,
      merchantSerialNumber,
      merchantSerialNumberPos,
      isTreeDriveAllowed,
      tdDiscountPermission,
      isNewReports,
      isWebOpperKonto,
      isDriveMyself,
      isTdvipps,
      isFindDriver,
      isTreeDriveDriveOutAllowed,
      isTreeDrivePickUpAllowed,
      tdDistance,
      tdServiceCharge,
      tdExtraPriceIncrease,
      tdFlatRateDriveOut,
      tdExtraRateDriveOut,
      tdStaticDriveOut,
      tdFlatDriveOutDistance,
      isWeekly,
      isVendorAllowed,
      isIngredientAllowed,
      tdDiscount,
      tdDiscountPickUp,
      reserveTablePermission,
      minimumOrder,
      machAccess,
      branchAddress,
      searchCity,
      branchType,
      range1Max,
      range1Min,
      discount1,
      range2Max,
      range2Min,
      discount2,
      range3Max,
      range3Min,
      discount3,
      range1MaxTA,
      range1MinTA,
      discount1TA,
      range2MaxTA,
      range2MinTA,
      discount2TA,
      range3MaxTA,
      range3MinTA,
      discount3TA,
      range4MaxTA,
      range4MinTA,
      discount4TA,
      range1MaxIDriveMyself,
      range1MinIDriveMyself,
      perKmFee1IDriveMyself,
      range2MaxIDriveMyself,
      range2MinIDriveMyself,
      perKmFee2IDriveMyself,
      range3MaxIDriveMyself,
      range3MinIDriveMyself,
      perKmFee3IDriveMyself,
      perDelFeeIDriveMyself,
      categoryBackColor,
      categoryTitleColor,
      productColor,
      priceColor,
      ingredentTextColor,
      orderNowBtnBackground,
      orderNowTextColor,
      viewCartTextColor,
      viewProductColor,
      this.state.qrcodeTablepermission,
      accountId,
      tidypayusername,
      tidypaypassword,
      tidypaypasswordweb,
      tidypaypassword6inch,
      minDepositAllowed,
      advanceDeposit,
      minDiffAllowed,
      webpayment,
      machinepayment,
      urlVippsDynamic,
      vippsClientId,
      vippsClientSecret,
      ocp_Apim_Subscription_key
    );
  };
  checkChange = (e) => {
    console.log(e);
  };

  /* radio button payment */
  selectedPaymentWeb = async (e) => {
    console.log("radio checked", e.target);
    await this.setState({
      webpayment: e.target.value
    });
  };
  selectedMachineAccess = async (value) => {
    console.log("selectedMachineAccess", value);
    await this.setState({
      machAccess: value
    });
  };
  selectedbranchCity = async (value) => {
    console.log("selectedMachineAccess", value);
    await this.setState({
      branchAddress: value,
      searchCity:value
    });
  };
  selectedIndustry = async (value) => {
    console.log("selected Industry type", value);
    await this.setState({
      branchType: value
    });
  };

  selectedPayment = async (e) => {
    console.log("radio checked", e.target);
    await this.setState({
      machinepayment: e.target.value
    });
  };
  render() {
    const { editing, superCats, devicesData } = this.props;
    const {
      name,
      email,
      phoneNo,
      taxNo,
      line1,
      line2,
      city,
      state,
      pin,
      country,
      tflamount,
      description,
      accountNumber,
      tdEnhPercentage,
      id,
      website,
      driveoutCharge,
      taccountantEmail,
      isRestaurantAvailable,
      restaurantEmail,
      kitchenpermission,
      barpermission,
      tippermission,
      deliverypermission,
      pickuppermission,
      qrcodeTablepermission,
      sCategory,
      inputVisible,
      sCatType,
      selectedDevices,
      categoryBackColor,
      categoryTitleColor,
      productColor,
      priceColor,
      ingredentTextColor,
      orderNowBtnBackground,
      orderNowTextColor,
      viewCartTextColor,
      viewProductColor,
      longitude,
      latitude,
      couponExpiresIn,
      couponPermissionChange,
      vippsPermissionChange,
      merchantSerialNumber,
      merchantSerialNumberPos,
      isTreeDriveAllowed,
      tdDiscountPermission,
      isNewReports,
      isWebOpperKonto,
      isDriveMyself,
      isTdvipps,
      isFindDriver,
      isTreeDriveDriveOutAllowed,
      isTreeDrivePickUpAllowed,
      tdDistance,
      tdServiceCharge,
      tdExtraPriceIncrease,
      tdFlatRateDriveOut,
      tdExtraRateDriveOut,
      tdStaticDriveOut,
      tdFlatDriveOutDistance,
      isWeekly,
      isVendorAllowed,
      isIngredientAllowed,
      tdDiscount,
      tdDiscountPickUp,
      reserveTablePermission,
      minimumOrder,
      machAccess,
      branchAddress,
      searchCity,
      branchType,
      range1Max,
      range1Min,
      discount1,
      range2Max,
      range2Min,
      discount2,
      range3Max,
      range3Min,
      discount3,
      range1MaxTA,
      range1MinTA,
      discount1TA,
      range2MaxTA,
      range2MinTA,
      discount2TA,
      range3MaxTA,
      range3MinTA,
      discount3TA,
      range4MaxTA,
      range4MinTA,
      discount4TA,
      range1MaxIDriveMyself,
      range1MinIDriveMyself,
      perKmFee1IDriveMyself,
      range2MaxIDriveMyself,
      range2MinIDriveMyself,
      perKmFee2IDriveMyself,
      range3MaxIDriveMyself,
      range3MinIDriveMyself,
      perKmFee3IDriveMyself,
      perDelFeeIDriveMyself,

      accountId,
      tidypayusername,
      tidypaypassword,
      tidypaypasswordweb,
      tidypaypassword6inch,
      // tidypaypermission,
      // dinteropermission,
      // verifonepermission,
      minDepositAllowed,
      advanceDeposit,
      minDiffAllowed,
      webpayment,
      machinepayment,
      urlVippsDynamic,
      vippsClientId,
      vippsClientSecret,
      ocp_Apim_Subscription_key
    } = this.state;

    const handleClickBack = (e) => {
      console.log(this.props.editing);
      this.props.editing = "true";
      // this.setState({ editing: false });
      console.log("Working");
    };
    return (
      <div id="addEditForm" style={{ margin: "20px 0" }}>
        {editing ? (
          <div>
            <div className="field is-horizontal" style={{ marginBottom: 0 }}>
              <div className="field-label is-normal">
                <label className="label " style={{ display: "flex" }}>
                  Super Categories :
                </label>
              </div>
              <div>
                {superCats &&
                  superCats.map((item, index) => {
                    return (
                      <Tag
                        closable
                        color="#CCA249 "
                        onClose={() => this.props.deleteSuperCategory(item._id)}
                        key={item._id}
                      >
                        {item.name}
                      </Tag>
                    );
                  })}
              </div>
              <br />

              <div className="field is-horizontal" style={{ marginLeft: 81 }}>
                <div className="field-body">
                  <div className="field">
                    {inputVisible && (
                      <div>
                        <Input
                          ref={this.saveInputRef}
                          type="text"
                          size="small"
                          style={{ width: 180, marginRight: 5, height: 32 }}
                          value={sCategory}
                          placeholder="Enter Super Category"
                          onChange={this.handleInputChange}
                        />

                        <Select
                          defaultValue=""
                          style={{ width: 90, marginRight: 10 }}
                          value={sCatType}
                          onChange={this.handletypeChange}
                        >
                          <Option value="">Type</Option>
                          <Option value="KITCHEN">KITCHEN</Option>
                          <Option value="BAR">BAR</Option>
                        </Select>

                        <button
                          style={{ fontSize: 13 }}
                          className="button is-primary"
                          onClick={() =>
                            this.svcat(this.state.id, sCategory, sCatType)
                          }
                        >
                          ADD
                        </button>

                        <button
                          style={{
                            fontSize: 13,
                            marginLeft: 5,
                            background: "red"
                          }}
                          className="button is-primary"
                          onClick={() => this.setState({ inputVisible: false })}
                        >
                          CANCEL
                        </button>
                      </div>
                    )}
                    {!inputVisible && (
                      <Tag
                        onClick={this.showInput}
                        style={{ background: "#fff", borderStyle: "dashed" }}
                      >
                        <Icon type="plus" /> New Category
                      </Tag>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label
                  style={{ padding: 0, display: "flex" }}
                  className="label"
                >
                  Profile Image :
                </label>
              </div>

              <div>
                <input
                  className="file-input"
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={this.handleLogoChange}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Divider />
        <form
          onSubmit={(e) => {
            this.submitSaveRestaurant(
              e,
              editing,
              name,
              email,
              phoneNo,
              taxNo,
              line1,
              line2,
              city,
              pin,
              state,
              country,
              tflamount,
              description,
              accountNumber,
              tdEnhPercentage,
              id,
              website,
              driveoutCharge,
              taccountantEmail,
              isRestaurantAvailable,
              restaurantEmail,
              kitchenpermission,
              barpermission,
              tippermission,
              deliverypermission,
              pickuppermission,
              this.state.selectedDevices,
              categoryBackColor,
              categoryTitleColor,
              productColor,
              priceColor,
              ingredentTextColor,
              orderNowBtnBackground,
              orderNowTextColor,
              viewCartTextColor,
              viewProductColor,
              qrcodeTablepermission,
              longitude,
              latitude,
              couponExpiresIn,
              couponPermissionChange,
              vippsPermissionChange,
              merchantSerialNumber,
              merchantSerialNumberPos,
              isTreeDriveAllowed,
              tdDiscountPermission,
              isNewReports,
              isWebOpperKonto,
              isDriveMyself,
              isTdvipps,
              isFindDriver,
              isTreeDriveDriveOutAllowed,
              isTreeDrivePickUpAllowed,
              tdDistance,
              tdServiceCharge,
              tdExtraPriceIncrease,
              tdFlatRateDriveOut,
              tdExtraRateDriveOut,
              tdStaticDriveOut,
              tdFlatDriveOutDistance,
              isWeekly,
              isVendorAllowed,
              isIngredientAllowed,
              tdDiscount,
              tdDiscountPickUp,
              reserveTablePermission,
              minimumOrder,
              machAccess,
              branchAddress,
              searchCity,
              branchType,
              range1Max,
              range1Min,
              discount1,
              range2Max,
              range2Min,
              discount2,
              range3Max,
              range3Min,
              discount3,
              range1MaxTA,
              range1MinTA,
              discount1TA,
              range2MaxTA,
              range2MinTA,
              discount2TA,
              range3MaxTA,
              range3MinTA,
              discount3TA,
              range4MaxTA,
              range4MinTA,
              discount4TA,
              range1MaxIDriveMyself,
              range1MinIDriveMyself,
              perKmFee1IDriveMyself,
              range2MaxIDriveMyself,
              range2MinIDriveMyself,
              perKmFee2IDriveMyself,
              range3MaxIDriveMyself,
              range3MinIDriveMyself,
              perKmFee3IDriveMyself,
              perDelFeeIDriveMyself,
              accountId,
              tidypayusername,
              tidypaypassword,
              tidypaypasswordweb,
              tidypaypassword6inch,
              // tidypaypermission,
              // dinteropermission,
              // verifonepermission,
              minDepositAllowed,
              advanceDeposit,
              minDiffAllowed,
              webpayment,
              machinepayment,
              urlVippsDynamic,
              vippsClientId,
              vippsClientSecret,
              ocp_Apim_Subscription_key
            );
          }}
        >
          {editing ? (
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" style={{ display: "flex" }}>
                  Current Devices :
                </label>
              </div>

              <div className="field-body">
                <div className="field" style={{ display: "flex" }}>
                  {this.props.restaurant.devices &&
                    this.props.restaurant.devices.map((dvs, index) => {
                      return (
                        <Popconfirm
                          title={
                            "You want to UN-BIND POS -" + dvs.deviceId &&
                            dvs.deviceId.deviceSerialNumber
                          }
                          onConfirm={() =>
                            this.removeDevice(
                              this.props.restaurant._id,
                              dvs.deviceId._id
                            )
                          }
                          // oncancel={this.cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <p
                            style={{
                              margin: 5,
                              background: "#CCA249 ",
                              padding: 10,
                              cursor: "pointer",
                              borderRadius: 30,
                              color: "white",
                              width: "35%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            POS-{dvs.deviceId.deviceSerialNumber},Model No. -
                            {dvs.deviceId.modelNumber}
                            <Icon
                              style={{
                                color: "red",
                                marginLeft: 10,
                                fontWeight: "bold"
                              }}
                              type="close"
                            />
                          </p>
                          {/* </Button> */}
                        </Popconfirm>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : null}

          {/* {!editing ? ( */}
          <div id="add-r">
            <Divider />
            <div style={{ background: "#3273dc", padding: "30px 0" }}>
              <h4 align="center" style={{ color: "#fff" }}>
             A-Board
              </h4>
            </div>
            <Divider />
            <div id="add-rbox-1">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Select Devices :</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <Select
                        mode="multiple"
                        placeholder="Select device"
                        value={this.state.deviceId}
                        onChange={this.changedevicePicker}
                        className="input"
                      >
                        {devicesData &&
                          devicesData.map((device, index) => {
                            return (
                              <Option key={index} value={device._id}>
                                POS-{device.deviceSerialNumber},Model No. -
                                {device.modelNumber}
                              </Option>
                            );
                          })}
                      </Select>
                      <span className="icon is-small is-left">
                        <i class="fab fa-android" style={{ color: "gold" }}></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Name*</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        required
                        className="input"
                        type="text"
                        name="name"
                        placeholder="Restaurant Name"
                        value={name}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-user" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal ">
                <div className="field-label is-normal">
                  <label className="label">Taccountant Email</label>
                </div>
                <div className="field-body">
                  <div className="field ">
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                        type="email"
                        name="taccountantEmail"
                        placeholder="Taccountant Email"
                        value={taccountantEmail}
                        onBlur={this.checkEmailPresence}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-link" />
                      </span>
                    </p>
                  </div>
                <div className="field-label is-normal">
                  <label className="label">Industry</label>
                </div>
                  <div className="field ">
                    {console.log("---branchType--------",branchType)}
                  <Select className="bordered-special"
                        defaultValue="rest"
                        style={{ width: "150px", marginRight: 10 }}
                        onChange={this.selectedIndustry}
                        value={branchType}
                      >
                        <Option value="">Not Given</Option>
                        <Option value="rest">Rest</Option>
                        <Option value="vendor">vendor</Option>
                      </Select>
                </div>
              </div>

              </div>
              <div className="field is-horizontal ">
                <div className="field-label is-normal">
                  <label className="label">Website*</label>
                </div>
                <div className="field-body">
                  <div className="field ">
                    <p className="control is-expanded has-icons-left">
                      <input
                        required
                        className="input"
                        type="text"
                        name="website"
                        placeholder="Website"
                        value={website}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-link" />
                      </span>
                    </p>
                  </div>
                  <div className="field-label is-normal">
                    <label className="label">Manager Email*</label>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        required
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onBlur={this.checkEmailPresence}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Phone No.*</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        required
                        className="input"
                        type="number"
                        name="phoneNo"
                        placeholder="Phone No."
                        value={phoneNo}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-phone" />
                      </span>
                    </p>
                  </div>
                  <div className="field-label is-normal">
                    <label className="label">Tax No.*</label>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        required
                        className="input"
                        type="number"
                        name="taxNo"
                        placeholder="Tax No."
                        value={taxNo}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-file-alt" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <>
                <Divider />
                <h4 align="center">Deposit Section</h4>
                <Divider />
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Minimum Deposit Allowed</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="number"
                          name="minDepositAllowed"
                          // disabled={editing ? true : false}
                          placeholder="Minimum Deposit Allowed"
                          value={minDepositAllowed}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="field-label is-normal">
                    <label className="label">Advance Deposit</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          // disabled={editing ? true : false}
                          className="input"
                          type="number"
                          name="advanceDeposit"
                          placeholder="Advance Deposit"
                          value={advanceDeposit}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="field-label is-normal">
                    <label className="label">Min Diff Allowed</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="number"
                          name="minDiffAllowed"
                          placeholder="minDiffAllowed"
                          value={minDiffAllowed}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>

              <>
                <Divider />
                <h4 align="center"> Restaurant Details</h4>
                <Divider />
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Branch Code*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="line1"
                          placeholder="Address line 1"
                          value={line1}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-address-card" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Line 2*</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="line2"
                          placeholder="Address line 2"
                          value={line2}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-address-card" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">City*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="city"
                          placeholder="City"
                          value={city}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i class="fas fa-city"></i>
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">PIN*</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="number"
                          name="pin"
                          placeholder="PIN"
                          value={pin}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-map-marker-alt" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">State*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="state"
                          placeholder="State"
                          value={state}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-city" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Country*</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="country"
                          placeholder="Country"
                          value={country}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-city" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Longitude</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="number"
                          name="longitude"
                          placeholder="Longitude"
                          value={longitude}
                          onChange={this.handleChange}
                          min="-180"
                          max="180"
                          step="any"
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-globe-asia" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Latitude</label>
                    </div>

                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="number"
                          name="latitude"
                          placeholder="Latitude"
                          value={latitude}
                          onChange={this.handleChange}
                          min="-90"
                          max="90"
                          step="any"
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-globe-asia" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Restaurant Email*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="email"
                          name="restaurantEmail"
                          placeholder="Restaurant Email"
                          value={restaurantEmail}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope" />
                        </span>
                      </p>
                    </div>

                    <div className="field-label is-normal">
                      <label className="label">Driveout Charges</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="number"
                          name="driveoutCharge"
                          placeholder="Driveout Charges"
                          value={driveoutCharge}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Account Number*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="text"
                          name="accountNumber"
                          placeholder="Account Number"
                          value={accountNumber}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-landmark" />
                        </span>
                      </p>
                    </div>
 <div className="field-label is-normal">
                  <label className="label">Enhanced % to client</label>
                </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                        type="text"
                        name="tdEnhPercentage"
                        placeholder="Enhanced %"
                        value={tdEnhPercentage}
                        onChange={this.handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-coins" />
                      </span>
                    </p>
                  </div>
                </div>

              </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Minimum Online DriveOut*</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          required
                          className="input"
                          type="number"
                          name="minimumOrder"
                          placeholder="minimumOrder"
                          value={minimumOrder}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-doller" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">City (For vendor)</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left flex"style={{ width:"150px" }}  >
                        {/* { <input
                          required
                          className="input"
                          type="text"
                          name="minimumOrder"
                          placeholder="Search City"
                          value={this.state.searchCity}
                          onChange={(e)=>this.FilterCityData(e)}
                        /> } */}
                          <Select
            className="m-4"
            showSearch
            style={{ width: "200px" }}
            placeholder="Select City"
            optionFilterProp="children"
            onChange={(e)=>this.selectedbranchCity(e)}
            value={this.state.searchCity}
                          // onChange={(e)=>this.FilterCityData(e)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            { this.state.filteredCityData.map((city) => (
              <Option key={city._id} value={city.name}>
                {city.name}
              </Option>
            ))}
          </Select>
                         {/* <Select className="bordered-special"
                 
                        placeholder="Select City"
                        optionFilterProp="children"
                        style={{ width: "150px", marginRight: 10 }}
                        onChange={(e)=>this.selectedbranchCity(e)}
                        value={""}
                        showSearch
                      
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      
                      >
                        
                       
                        {
                          this.state.filteredCityData.map((city,idx)=>{
                            
                           return <Option key={idx} value={city}>
                                  {city.name}
                                </Option>
                          })
                        }
                      </Select> */}
                        <span className="icon is-small is-left">
                          <i className="fas fa-doller" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Description</label>
                    </div>
                    <div className="field" style={{ width: "67%" }}>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name="description"
                          value={description}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>

              <div>
                <Divider />
                <h4 align="center"> SPECIAL ORC PERMISSIONS</h4>
                <Divider />
                <div className="field is-horizontal orc-permission">
                  <div className="field-body">
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Kitchen
                        </label>
                      </div>
                      <div className="field">
                        <div className="control is-expanded">
                          <Switch
                            value={kitchenpermission}
                            checked={kitchenpermission}
                            onChange={this.kitchenpermissionchange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">Tip</label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={tippermission}
                            checked={tippermission}
                            onChange={this.tippermissionChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Delivery (Web iframe)
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={deliverypermission}
                            checked={deliverypermission}
                            onChange={this.deliverypermissionChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Pick-up (Web iframe)
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={pickuppermission}
                            checked={pickuppermission}
                            onChange={this.pickuppermissionChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {starting of coupon permission */}
                <div className="field is-horizontal orc-permission">
                  <div className="field-body">
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">Coupon</label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={couponPermissionChange}
                            checked={couponPermissionChange}
                            onChange={this.couponPermissionChange}
                          />
                        </div>
                      </div>
                    </div>
                    {editing && (
                      <div
                        style={{ display: "flex" }}
                        className="bordered-special"
                      >
                        <div className="field-label is-normal">
                          <label className="label couponPermission">
                            Table-QR
                          </label>
                        </div>
                        <div className="field">
                          <div className="control">
                            <Switch
                              checked={qrcodeTablepermission}
                              onChange={this.qrCodePickupPermissionChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Reserve Table
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={reserveTablePermission}
                            checked={reserveTablePermission}
                            onChange={this.reserveTablePermissionHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Weekly Report
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={this.state.isWeekly}
                            checked={this.state.isWeekly}
                            onChange={this.isWeeklyFunction}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal orc-permission">
                  <div className="field-body">
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          TreeDrive
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Switch
                              value={isTreeDriveAllowed}
                              checked={isTreeDriveAllowed}
                              onChange={this.isTreeDriveAllowed}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                        WebOpperKonto
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={ isWebOpperKonto}
                            checked= {isWebOpperKonto}
                            onChange={this. isWebOpperKonto}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                          New Pdf Reports
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={isNewReports}
                            checked={isNewReports}
                            onChange={this.isNewReports}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                        Online Availablity (web)
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={isRestaurantAvailable}
                            checked={isRestaurantAvailable}
                            onChange={this.isRestaurantAvailable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal orc-permission2">
                  <div className="field-body">
                <div className="bordered-specialNew">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                        isVendor Allowed
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={this.state.isVendorAllowed}
                            checked={this.state.isVendorAllowed}
                            onChange={this.isVendorAllowedFunction}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bordered-special">
                      <div className="field-label is-normal">
                        <label className="label couponPermission">
                        isIngredient Allowed
                        </label>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Switch
                            value={this.state.isIngredientAllowed}
                            checked={this.state.isIngredientAllowed}
                            onChange={this.isIngredientAllowedFunction}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
              </div>
              <Divider />
              <div>
                {/* merchant serial number position starts */}

              

{/*               
                <Divider /> */}
                <div className="field is-horizontal">
                  <div className="bordered-special" style={{ width: "100%" }}>
                    <div className="field-label is-normal">
                      <label className="label couponPermission">Vipps</label>
                    </div>
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={vippsPermissionChange}
                          checked={vippsPermissionChange}
                          onChange={this.vippsPermissionChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    {/* <label className="label">Merchant Serial Number</label> */}
                    <label className="label">Vipps Web</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="merchantSerialNumber"
                          placeholder="Vipps Web"
                          value={merchantSerialNumber}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                    {/* <label className="label">Merchant Serial Number POS</label> */}
                    {/* <div className="field-label is-normal">
                      <label className="label">Vipps POS</label>
                    </div> */}
                    {/* <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="merchantSerialNumberPos"
                          placeholder="Vipps POS"
                          value={merchantSerialNumberPos}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div> */}
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Vipps urlVippsDynamic</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="urlVippsDynamic"
                          placeholder="urlVippsDynamic"
                          value={urlVippsDynamic}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Vipps Client Id</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="vippsClientId"
                          placeholder="vippsClientId"
                          value={vippsClientId}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Vipps Client Secret</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="vippsClientSecret"
                          placeholder="vippsClientSecret"
                          value={vippsClientSecret}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Ocp_Apim_Subscription_key</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="ocp_Apim_Subscription_key"
                          placeholder="ocp_Apim_Subscription_key"
                          value={ocp_Apim_Subscription_key}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <Divider />
                {/* radio button payment */}
                <div className="field is-horizontal orc-permission">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Web Payment Method</label>
                    </div>
                    <div className="field">
                      <Radio.Group
                        className="bordered-special"
                        onChange={this.selectedPaymentWeb}
                        value={this.state.webpayment}
                      >
                        <Radio value={"tidypay"}>Tidypay</Radio>
                        <Radio value={"dintero"}>Dintero</Radio>
                        <Radio value={"none"}>None</Radio>
                      </Radio.Group>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label couponPermission">
                        Machine Payment Method
                      </label>
                    </div>
                    <div className="field">
                      <Radio.Group
                        className="bordered-special"
                        onChange={this.selectedPayment}
                        value={this.state.machinepayment}
                      >
                        <Radio value={"tidypay"}>Tidypay</Radio>
                        <Radio value={"verifone"}>Verifone</Radio>
                        <Radio value={"omCloud"}>Om Cloud</Radio>
                        <Radio value={"both"}>Both</Radio>
                        <Radio value={"none"}>None</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <Divider />

                {/* <div className="field-label is-normal">
                    <label className="label couponPermission">Web Payment Mode</label>
                  </div>
                  <div className="field">
                    <div className="control">
                      <Switch
                        value={this.state.tidypaypermission}
                        checked={this.state.tidypaypermission}
                        onChange={this.tidypaypermissionFunction}
                      />
                    </div>
                  </div> */}
                {/* <div className="bordered-special">
            <div className="field-label is-normal">
                        <label className="label couponPermission">
                          Machine Payment Mode
                        </label>
            </div>
            <div className="field">
                        <div className="control">
                          <Switch
                            value={this.state.tidypaypermission}
                            checked={this.state.tidypaypermission}
                            onChange={this.tidypaypermissionFunction}
                          />
                        </div>
                      </div>
                    </div> */}

                {/* Tidypay */}
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Tidypay Username</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="tidypayusername"
                          placeholder="Tidypay Username"
                          value={tidypayusername}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Tidypay Password 15 Inch</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="tidypaypassword"
                          placeholder="Tidypay Password"
                          value={tidypaypassword}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Tidypay Account Id</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="accountId"
                          placeholder="Tidypay Account Id"
                          value={accountId}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                    <div className="field-label is-normal">
                      <label className="label">Tidypay Password Web</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="tidypaypasswordweb"
                          placeholder="Tidypay Password Web"
                          value={tidypaypasswordweb}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div class="field-body">
                    <div className="field-label is-normal">
                      <label className="label">Tidypay Password 6 INCH</label>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          name="tidypaypassword6inch"
                          placeholder="Tidypay Password 6 INCH"
                          value={tidypaypassword6inch}
                          onChange={this.handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-coins" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Divider />
              <h4 align="center"> ONLINE THEME COLORS</h4>
              <Divider />

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field-label is-normal">
                    <label className="label">Category Background color</label>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                    type="text"
                    name="categoryBackColor"
                    placeholder="Category Background Color"
                    value={categoryBackColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: categoryBackColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>

            <div className="field-label is-normal">
              <label className="label">Category Title Color</label>
            </div>
            <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="categoryTitleColor"
                    placeholder="Category Background Color"
                    value={categoryTitleColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: categoryTitleColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
          <div className="field-body">
            <div className="field-label is-normal">
              <label className="label">Product color</label>
            </div>
            <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="productColor"
                    placeholder="Product Color"
                    value={productColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: productColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>

            <div className="field-label is-normal">
              <label className="label">Price Color</label>
            </div>
            <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="priceColor"
                    placeholder="Price Color"
                    value={priceColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                        <i
                          style={{ color: priceColor }}
                          className="fa fa-circle"
                        />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
            <div className="field is-normal">
              <label className="label">Ingredients Text color</label>
            </div>
                  <div className="field" style={{ width: "79%" }}>
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="ingredentTextColor"
                    placeholder="Ingredients Text color"
                    value={ingredentTextColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: ingredentTextColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
            <div className="field-label is-normal">
              <label className="label">Order Now Button Background</label>
            </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="orderNowBtnBackground"
                    placeholder="Order now background color"
                    value={orderNowBtnBackground}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: orderNowBtnBackground }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>

            <div className="field-label is-normal">
              <label className="label">Order Now Text Color</label>
            </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="orderNowTextColor"
                    placeholder="Order Now Text Color"
                    value={orderNowTextColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: orderNowTextColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              
            <div className="field-label is-normal">
              <label className="label">View Cart Text Color</label>
            </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="viewCartTextColor"
                    placeholder="View Cart Text Color"
                    value={viewCartTextColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: viewCartTextColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>
              <div className="field-label is-normal">
              <label className="label">View Product text color</label>
            </div>
            <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    name="viewProductColor"
                    placeholder="Order Now Text Color"
                    value={viewProductColor}
                    onChange={this.handleChange}
                  />
                  <span className="icon is-small is-left">
                    <i
                      style={{ color: viewProductColor }}
                      className="fa fa-circle"
                    />
                  </span>
                </p>
              </div>
            </div>

            
          </div> */}

              {/* -------------------- */}
            </div>

            {/* merchantSerialnumber ends */}

            {/*--------------tree drive special -------------------------*/}
            <div>
              <Divider />
              <div
                style={{
                  background: "#12C751",
                  color: "#fff",
                  padding: "30px 0"
                }}
              >
                <h4 align="center" style={{ color: "#fff" }}>
                  Tree Drive
                </h4>
              </div>
              <Divider />
              <div className="field is-horizontal orc-permission">
                {/*isTreeDriveDriveOutAllowed starts  */}
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">
                      TD DriveOut
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={isTreeDriveDriveOutAllowed}
                          checked={isTreeDriveDriveOutAllowed}
                          onChange={this.isTreeDriveDriveOutAllowed}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* isTreeDriveDriveOutAllowed endes */}
                {/*isTreeDrivePickUpAllowed starts  */}
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">TD PickUp</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={isTreeDrivePickUpAllowed}
                          checked={isTreeDrivePickUpAllowed}
                          onChange={this.isTreeDrivePickUpAllowed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* isTreeDrivePickUpAllowed endes */}
                {/*isDriveMyself starts  */}
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">
                      isDriveMyself
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={isDriveMyself}
                          checked={isDriveMyself}
                          onChange={this.isDriveMyself}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* isDriveMyself endes */}

                {/* isTdvipps,  */}
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">
                     TD Vipps
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={isTdvipps}
                          checked={isTdvipps}
                          onChange={this.isTdvipps}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*  isTdvipps,endes */}
                 {/* isTdvipps,  */}
              </div>
                <div className="field is-horizontal orc-permission">
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">
                      Find Driver
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Switch
                          value={isFindDriver}
                          checked={isFindDriver}
                          onChange={this.isFindDriver}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*  isTdvipps,endes */}
                {/*tdDiscountPermission starts  */}
                <div className="bordered-special">
                  <div className="field-label is-normal">
                    <label className="label couponPermission">
                      TD Discount
                    </label>
                  </div>
                  <div className="field">
                    <div className="control">
                      <Switch
                        value={tdDiscountPermission}
                        checked={tdDiscountPermission}
                        onChange={this.tdDiscountPermission}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*tdDiscountPermission ends  */}
              <br />
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Machine access</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                    <Select className="bordered-special"
                        defaultValue=""
                        style={{ width: "150px", marginRight: 10 }}
                        onChange={this.selectedMachineAccess}
                        value={machAccess}
                      >
                        <Option value="">Not Given</Option>
                        <Option value="15inchi">ORC Full Access</Option>
                        <Option value="treedrive">Treedrive 6 Inch</Option>
                      </Select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-doller" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            <div align="center">
              <h4> TD SPECIAL SECTION</h4>
            </div>
            <Divider />
            {/* tree drive starts */}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">TD Service Charges(Kr.)<br/>(App Fee)</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdServiceCharge"
                      placeholder="TD service charge"
                      value={tdServiceCharge}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">
                    TD Distance allowed by Restaurant(Kms)
                  </label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdDistance"
                      placeholder="TD distance allowed by Restaurant"
                      value={tdDistance}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">
                    Extra Percentage Price Show(%)
                  </label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdExtraPriceIncrease"
                      placeholder="TD increased price to be shown"
                      value={tdExtraPriceIncrease}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-percent"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Flat rate for DriveOut(Kr.)</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdFlatRateDriveOut"
                      placeholder="TD flat rate"
                      value={tdFlatRateDriveOut}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">TD Extra DriveOut(Kr.) / Km</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdExtraRateDriveOut"
                      placeholder="TD extra drive out"
                      value={tdExtraRateDriveOut}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Distance for flat rate(Kms)</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdFlatDriveOutDistance"
                      placeholder="TD flat rate distance"
                      value={tdFlatDriveOutDistance}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                
                
                <div className="field-label is-normal">
                  <label className="label">TD special Drive out <br/> (Restaurant Paid us)</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      name="tdStaticDriveOut"
                      placeholder="TD special Drive out"
                      value={tdStaticDriveOut}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>


                
                <div className="field-label is-normal">
                  {/* <label className="label">TD Extra DriveOut(Kr.) / Km</label> */}
                </div>
                <div className="field">
                  {/* <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="tdExtraRateDriveOut"
                      placeholder="TD extra drive out"
                      value={tdExtraRateDriveOut}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p> */}
                </div>

               

              
              </div>
            </div>
            {/*NEW ROW ENDS */}

            {/*NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">TREE DRIVE EXTRA DRIVE OUT(Kr.)</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="tdExtraRateDriveOut"
                  placeholder="TREE DRIVE EXTRA DRIVE OUT"
                  value={tdExtraRateDriveOut}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-coins" />
                </span>
              </p>
            </div>
            <div className="field-label is-normal">
              <label className="label">DISTANCE FOR FLAT RATE</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="tdFlatDriveOutDistance"
                  placeholder="TREE DRIVE FLAT RATE DISTANCE"
                  value={tdFlatDriveOutDistance}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-coins" />
                </span>
              </p>
            </div>
          </div> */}
            {/*NEW ROW ENDS */}
            {/* NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tree Drive Discount On Drive Out</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="tdDiscount"
                  placeholder="TREE DRIVE Discount %"
                  value={tdDiscount}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-coins" />
                </span>
              </p>
            </div>

            <div className="field-label is-normal">
              <label className="label">Tree Drive Discount ON PickUp</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="tdDiscountPickUp"
                  placeholder="TREE DRIVE Discount pick up %"
                  value={tdDiscountPickUp}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-coins" />
                </span>
              </p>
            </div>
          </div> */}

            {/*NEW ROW ENDS */}

            <Divider />
            <h4 align="center"> TD DriveOut Discount Section</h4>

            <Divider />
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 1 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range1Min"
                      placeholder="Minimum Price in Kr."
                      value={range1Min}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 1 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range1Max"
                      placeholder="Maximum Price in Kr."
                      value={range1Max}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 1 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount1"
                      placeholder="TD Discount %"
                      value={discount1}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-percent"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}

            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 2 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range2Min"
                      placeholder="Minimum Price in Kr."
                      value={range2Min}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 2 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range2Max"
                      placeholder="Maximum Price in Kr."
                      value={range2Max}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 2 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount2"
                      placeholder="TD Discount %"
                      value={discount2}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-percent"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 3 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range3Min"
                      placeholder="Minimum Price in Kr."
                      value={range3Min}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 3 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range3Max"
                      placeholder="Maximum Price in Kr."
                      value={range3Max}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 3 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount3"
                      placeholder="TD Discount %"
                      value={discount3}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-percent"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}
            <Divider />
            <h4 align="center"> TD TakeAway Discount Section</h4>
            <Divider />
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 1 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range1MinTA"
                      placeholder="Minimum Price in Kr."
                      value={range1MinTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 1 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range1MaxTA"
                      placeholder="Maximum Price in Kr."
                      value={range1MaxTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-coins"></i>
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 1 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount1TA"
                      placeholder="TD Discount %"
                      value={discount1TA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i class="fas fa-percent"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}

            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 2 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range2MinTA"
                      placeholder="Minimum Price in Kr."
                      value={range2MinTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 2 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range2MaxTA"
                      placeholder="Maximum Price in Kr."
                      value={range2MaxTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 2 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount2TA"
                      placeholder="TD Discount %"
                      value={discount2TA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-percent" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 3 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range3MinTA"
                      placeholder="Minimum Price in Kr."
                      value={range3MinTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 3 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range3MaxTA"
                      placeholder="Maximum Price in Kr."
                      value={range3MaxTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 3 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount3TA"
                      placeholder="TD Discount %"
                      value={discount3TA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-percent" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 4 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range4MinTA"
                      placeholder="Minimum Price in Kr."
                      value={range4MinTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>

                <div className="field-label is-normal">
                  <label className="label">Price Range 4 Max Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range4MaxTA"
                      placeholder="Maximum Price in Kr."
                      value={range4MaxTA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-coins" />
                    </span>
                  </p>
                </div>
                <div className="field-label is-normal">
                  <label className="label">Discount 4 in %</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="discount4TA"
                      placeholder="TD Discount %"
                      value={discount4TA}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-percent" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/*NEW ROW ENDS */}
            {/*tred drive ends */}

            {/*-----------------Idrive myself start------------------------*}
                 {/*NEW ROW ENDS */}
            {/*NEW ROW ENDS */}
            {/* <Divider /> */}
            {/* <h4 align="center"> TD Restaurant Drive Myself Section</h4> */}
            {/* <Divider /> */}
            {/*NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 1 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range1MinIDriveMyself"
                      placeholder="Minimum Price in Kr."
                      value={range1MinIDriveMyself}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-piggy-bank" />
                    </span>
                  </p>
                </div>

            <div className="field-label is-normal">
              <label className="label">Price Range 1 Max Kr.</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="range1MaxIDriveMyself"
                  placeholder="Maximum Price in Kr."
                  value={range1MaxIDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            <div className="field-label is-normal">
              <label className="label">Per Km Fee</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="perKmFee1IDriveMyself"
                  placeholder="PER KM FEE KR."
                  value={perKmFee1IDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            </div>
            </div> */}

            {/*NEW ROW ENDS */}

            {/*NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 2 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range2MinIDriveMyself"
                      placeholder="Minimum Price in Kr."
                      value={range2MinIDriveMyself}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-piggy-bank" />
                    </span>
                  </p>
                </div>

            <div className="field-label is-normal">
              <label className="label">Price Range 2 Max Kr.</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="range2MaxIDriveMyself"
                  placeholder="Maximum Price in Kr."
                  value={range2MaxIDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            <div className="field-label is-normal">
              <label className="label">Per Km Fee</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="perKmFee2IDriveMyself"
                  placeholder="PER KM FEE KR."
                  value={perKmFee2IDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            </div>
            </div> */}

            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Price Range 3 Min Kr.</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="range3MinIDriveMyself"
                      placeholder="Minimum Price in Kr."
                      value={range3MinIDriveMyself}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-piggy-bank" />
                    </span>
                  </p>
                </div>

            <div className="field-label is-normal">
              <label className="label">Price Range 3 Max Kr.</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="range3MaxIDriveMyself"
                  placeholder="Maximum Price in Kr."
                  value={range3MaxIDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            <div className="field-label is-normal">
              <label className="label">Per Km Fee</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="perKmFee3IDriveMyself"
                  placeholder="PER KM FEE KR."
                  value={perKmFee3IDriveMyself}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div>
            </div>
            </div> */}

            {/*NEW ROW ENDS */}
            {/*NEW ROW STARTS*/}
            {/* <div className="field is-horizontal">
              <div className="field-body">
                <div className="field-label is-normal">
                  <label className="label">Per Delivery Fee</label>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      name="perDelFeeIDriveMyself"
                      placeholder="PER DELIVERY FEES IN  Kr."
                      value={perDelFeeIDriveMyself}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-piggy-bank" />
                    </span>
                  </p>
                </div>

            {/* <div className="field-label is-normal">
              <label className="label">Price Range 4 Max Kr.</label>
            </div> */}
            {/* <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="range4MaxTA"
                  placeholder="Maximum Price in Kr."
                  value={range4MaxTA}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div> */}
            {/* <div className="field-label is-normal">
              <label className="label">Discount 4 in %</label>
            </div>
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="discount4TA"
                  placeholder="TREE DRIVE Discount %"
                  value={discount4TA}
                  onChange={this.handleChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-piggy-bank" />
                </span>
              </p>
            </div> */}
            {/* </div> */}
            {/* </div> */}

            {/*NEW ROW ENDS */}
            {/*tred drive ends */}

            {/*-----------------Idrive myself end------------------------*/}

            <Divider />
            <div style={{ background: "#774c2b", padding: "30px 0" }}>
              <h4 align="center" style={{ color: "#fff" }}>
                Tree For Life
              </h4>
            </div>
            <Divider />

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">TFL Amount*</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      required
                      className="input"
                      type="text"
                      name="tflamount"
                      placeholder="TFL Amount"
                      value={tflamount}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-doller" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">TFL Code Expiry(Months)</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      name="couponExpiresIn"
                      placeholder="Coupon Expires In Months"
                      value={couponExpiresIn}
                      onChange={this.handleChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-doller" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <Divider />
            <div className="field is-horizontal">
              <div className="field-label" />
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    {editing ? (
                      <button className="button is-primary">
                        Save Restaurant
                      </button>
                    ) : (
                      <button className="button is-primary">
                        Add Restaurant
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RestaurantForm;
